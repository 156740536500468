import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Headline from '../../components/headline';

const Programas = ({ data }) => (
  <Layout>
    <SEO
      title="Programas"
      description="Cada vez que tú decides ayudar a uno de nuestros pequeños y a sus familias les estás dando prácticamente una espada desenvainada para que ellos puedan vencer cada batalla que pelean durante el proceso, así que ten por seguro que la vida te recompensará esas acciones justo en donde lo necesites."
    />
    <Container>
      <Headline>Nuestras programas</Headline>
      <p>
        Cada vez que tú decides ayudar a uno de nuestros pequeños y a sus
        familias les estás dando prácticamente una espada desenvainada para que
        ellos puedan vencer cada batalla que pelean durante el proceso, así que
        ten por seguro que la vida te recompensará esas acciones justo en donde
        lo necesites.
      </p>
      <Programs>
        <Card>
          <Link to="/programas/te-entapamos-con-amor">
            <Img fluid={data.TeEntapamosConAmor.childImageSharp.fluid} />
            <h2>
              #Te
              <wbr />
              Entapamos
              <wbr />
              Con
              <wbr />
              Amor
            </h2>
          </Link>
        </Card>
        <Card>
          <Link to="/programas/hilos-de-amor">
            <Img fluid={data.HilosDeAmor.childImageSharp.fluid} />
            <h2>Hilos de Amor</h2>
          </Link>
        </Card>
        <Card>
          <Link to="/programas/apadrina-a-un-nino">
            <Img fluid={data.ApadrinaUnNino.childImageSharp.fluid} />
            <h2>Apadrina a un niño</h2>
          </Link>
        </Card>
      </Programs>
    </Container>
  </Layout>
);

Programas.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Programas;

const Programs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    position: absolute;
  }
  h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 1rem;
    display: block;
    color: #fff;
    text-shadow: 1px 1px 2px #000;
    background: rgba(54, 113, 175, 0.75);
    width: 100%;
  }
`;

export const query = graphql`
  query {
    TeEntapamosConAmor: file(relativePath: { regex: "/bottle-caps.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    HilosDeAmor: file(relativePath: { regex: "/hilosdeamor.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ApadrinaUnNino: file(relativePath: { regex: "/apadrina.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
